<template>
  <v-dialog v-model="dialog" persistent max-width="700px">
    <v-card class="pb-0 mb-0">
      <v-card-title v-if="title">
        <div class="headline secondary--text">{{ $t(title) }}</div>
      </v-card-title>
      <v-card-text>
        <v-container>
          <slot />
        </v-container>
      </v-card-text>
      <v-card-actions v-if="!noActions" class="pbb-3">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="sendResult"
          v-if="sendResult"
          elevation="0"
          class="pa-5"
        >
          <span class="body-1 white--text">
            {{ $t('confirm') }}
          </span>
        </v-btn>
        <v-btn color="secondary" outlined @click="close" class="pa-5">
          <span class="body-1 secondary--text">
            {{ $t(closeTxt || 'close') }}
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  // props: ['dialog', 'title', 'sendResult', 'closeTxt'],
  props: {
    dialog: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    sendResult: {
      // type: Function,
      default: false,
    },
    closeTxt: {
      // type: String,
      default: false,
    },
    noActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit('closeDialog');
    },
  },
};
</script>

<style></style>
